import OverlayContainer from '@/components/Portal/OverlayContainer'
import { DEFAULT_CURRENCY_NAME } from '@/ts/constant'
import { enableStaticRendering } from 'mobx-react-lite'
import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import useCookie from 'src/hook/useCookies'
import { RootStore, RootStoreHydration } from '../stores/RootStore'

enableStaticRendering(typeof window === 'undefined')

let store: RootStore
const StoreContext = createContext<RootStore | undefined>(undefined)
StoreContext.displayName = 'StoreContext'

export function useRootStore() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }

  return context
}

export function useCurrencyStore() {
  const { currencyStore } = useRootStore()
  return currencyStore
}
export function useModalStore() {
  const { modalStore } = useRootStore()
  return modalStore
}

function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore()

  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function RootStoreProvider({
  children,
  hydrationData,
  isSSR = false,
}: {
  children: ReactNode
  hydrationData?: RootStoreHydration
  isSSR: boolean
}) {
  const store = initializeStore(hydrationData)
  const [data, updateData] = useCookie('currency')

  useEffect(() => {
    if (!data) {
      updateData(DEFAULT_CURRENCY_NAME, 3)
    }
    if (!isSSR) {
      store.initdata()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StoreContext.Provider value={store}>
      {children}
      <OverlayContainer />
    </StoreContext.Provider>
  )
}
