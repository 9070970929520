type MethodTypes = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export function fetchApi<T, TDataInput = any>(
  url: string,
  data?: TDataInput,
  method: MethodTypes = 'GET',
  token?: string
): Promise<T> {
  const requestUrl = `${url}${
    method === 'GET' && data
      ? `?variables=${encodeURI(JSON.stringify(data))}`
      : ''
  }`
  return fetch(requestUrl, {
    body: method !== 'GET' ? JSON.stringify(data) : undefined,
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json() as Promise<T>
  })
}
