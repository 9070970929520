import { Currencies } from '@/ts/graphqlschema'
import { CurrencyStore } from './CurrencyStore'
import { ModalStore } from './ModalStore'

export type RootStoreHydration = {
  currencies: Currencies[]
  mainCurrency: Currencies
}

export class RootStore {
  currencyStore: CurrencyStore
  modalStore: ModalStore

  constructor() {
    this.currencyStore = new CurrencyStore(this)
    this.modalStore = new ModalStore(this)
  }

  initdata() {
    this.currencyStore.fetchCurrency()
  }

  hydrate(data: RootStoreHydration) {
    if (data.currencies) {
      this.currencyStore.hydrate(data)
      this.modalStore.hydrate({ modalData: null, isOpenModal: false })
    }
  }
}
