import { Currencies, Get_CurrenciesQuery } from '@/ts/graphqlschema'
import { fetchApi } from '@/utils/api'
import { observable, makeObservable, action, flow } from 'mobx'
import { getItem } from 'src/hook/useCookies'
import { RootStore } from './RootStore'

export type CurrencyHydration = {
  currencies: Currencies[]
  mainCurrency: Currencies
}

export class CurrencyStore {
  root: RootStore
  public currencies: Currencies[] = []
  public mainCurrency: Currencies = undefined

  constructor(root: RootStore) {
    this.root = root
    makeObservable(
      this,
      {
        currencies: observable,
        mainCurrency: observable,
        setMainCurrency: action,
        formatCurrency: action,
        fetchCurrency: flow,
      },
      {
        autoBind: true,
      }
    )
  }

  *fetchCurrency(): Generator<
    Promise<Get_CurrenciesQuery>,
    Get_CurrenciesQuery,
    Get_CurrenciesQuery
  > {
    this.currencies = []
    this.mainCurrency = null
    try {
      const data = yield fetchApi<Get_CurrenciesQuery>('/api/currencies')
      this.currencies = data.currencies
      this.mainCurrency = data.currencies.find(
        cur => cur.name === getItem('currency')
      )

      return data
    } catch (error) {}
  }

  setMainCurrency(currencyName: string): void {
    this.mainCurrency = this.currencies.find(c => c.name === currencyName)
  }

  hydrate(data?: CurrencyHydration): void {
    this.currencies = data.currencies
    this.mainCurrency = data.mainCurrency
  }

  formatCurrency(price: number): string {
    return (
      new Intl.NumberFormat('en-US').format(
        price / this.mainCurrency.current_rate
      ) + this.mainCurrency.symbol
    )
  }
}
