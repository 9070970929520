import { FC, useEffect, useRef, useState } from 'react'
import { useModalStore, useRootStore } from 'src/providers/GlobalProvider'
import { observer } from 'mobx-react-lite'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion, Variants } from 'framer-motion'

const overlayVariants: Variants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      duration: 0.3,
      delayChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      duration: 0.3,
      delay: 0.4,
    },
  },
}

const Modal: FC = () => {
  const { modalStore } = useRootStore()
  const { modalData } = useModalStore()

  return (
    <AnimatePresence>
      {
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
          className="modal-overlay"
        >
          <motion.div
            className="modal"
            initial={{ y: '100vh' }}
            animate={{ y: 0 }}
            exit={{ y: '100vh' }}
            transition={{ duration: 0.5 }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{modalData.title}</h5>
            </div>
            <div className="modal-content">{modalData.body}</div>
            <div className="modal-footer">
              <button
                className="modal-button"
                onClick={() => modalStore.setIsOpenModal(false)}
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      }
    </AnimatePresence>
  )
}

const OverlayContainer: FC = observer(() => {
  const { isOpenModal } = useModalStore()
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal')
    setMounted(true)
  }, [])

  return isOpenModal && mounted && ref.current
    ? createPortal(<Modal />, ref.current)
    : null
})

export default OverlayContainer
