import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { client } from '@/graphql/apollo-client'
import { RootStoreProvider } from 'src/providers/GlobalProvider'
import { AnimatePresence } from 'framer-motion'
import { RootStoreHydration } from 'src/stores/RootStore'
import React from 'react'
// import { useRouter } from 'next/router'
// import { useEffect } from "react";
// import { m } from 'framer-motion';
// import { useState } from 'react'

function CustomApp({
  Component,
  pageProps,
}: AppProps<{ hydrationData: RootStoreHydration; isSSR: boolean }>) {
  // const router = useRouter()
  // const [isCurtainOn, setCurtainOn] = useState(true);
  // const [isCurtainShop, setCurtainShop] = useState(true);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     const isShopSlug = url.includes('/shop/all-cycling-tours')
  //     const isHome = url === '/'
  //     const isProductSlug = url.includes('product-detail')
  //     if (isShopSlug) {
  //       setCurtainShop(true)
  //     }
  //     if (isHome || isShopSlug || isProductSlug) {
  //       setCurtainOn(true)
  //     }
  //   }
  //   const handleComplete = (url) => (url === router.asPath) && setTimeout(() =>{setCurtainOn(false), setLoading(false)},2000);
  //
  //
  //   router.events.on('routeChangeStart', handleRouteChange)
  //   router.events.on('routeChangeComplete', handleComplete)
  //
  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method:
  //   return () => {
  //     router.events.off('routeChangeStart', handleRouteChange)
  //     router.events.off('routeChangeComplete', handleComplete)
  //   }
  // }, [router])
  //
  // /**FIRST PAGE LOAD*/
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //     setCurtainOn(false);
  //   }, 3000);
  // }, []);

  return (
    <RootStoreProvider
      hydrationData={{ ...pageProps.hydrationData }}
      isSSR={pageProps.isSSR}
    >
      <AnimatePresence mode="wait" initial={false}>
        <ApolloProvider client={client}>
          <Component {...pageProps} isCurtainShop />
        </ApolloProvider>
      </AnimatePresence>
    </RootStoreProvider>
  )
}
export default CustomApp
