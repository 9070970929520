import { observable, makeObservable, action } from 'mobx'
import { ReactNode } from 'react'
import { RootStore } from './RootStore'

export type CurrencyHydration = {
  isOpenModal: boolean
  modalData: ModalData
}

export type ModalData = {
  title?: string
  body?: string[] | string | ReactNode
}

export class ModalStore {
  root: RootStore
  public isOpenModal: boolean = false
  public modalData: ModalData = undefined

  constructor(root: RootStore) {
    this.root = root
    makeObservable(
      this,
      {
        isOpenModal: observable,
        modalData: observable,
        setFullModalData: action,
        setModalData: action,
        setIsOpenModal: action,
      },
      {
        autoBind: true,
      }
    )
  }

  setFullModalData(modalData?: ModalData, isOpenModal?: boolean): void {
    this.isOpenModal = isOpenModal
    this.modalData = modalData
  }

  setIsOpenModal(isOpenModal: boolean): void {
    this.isOpenModal = isOpenModal
  }

  setModalData(modalData: ModalData): void {
    if (modalData) this.modalData = modalData
  }

  hydrate({ modalData, isOpenModal }: CurrencyHydration): void {
    this.modalData = modalData
    this.isOpenModal = isOpenModal
  }
}
